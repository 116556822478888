import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CompanyNodeTree from '../../../tools/CompanyNodeTree';
import {
    ENTITY_PART_TYPE_PLACEHOLDER,
    ENTITY_PART_TYPE_GAIN_ACCOUNT,
    ENTITY_PART_TYPE_SEPARATOR,
    ENTITY_PART_TYPE_GAIN_ACCOUNT_GROUP,
    ENTITY_PART_TYPE_CUSTOM_ACCOUNT,
} from '../../../models/common/EntityPart';
import FilterTreeView from './FilterTreeView';
import ThirdPartyCompanyApi from '../../../api/thirdPartyCompanyApi';
import { blankKeyText } from '../../../constants/gridText';
import { FilterGenerator } from './FilterGenerator';
import { KEY_ENTER } from '../../../constants/keyboardCodes';

class CompaniesFilter extends Component {
    constructor(props) {
        super(props);

        this.inputRef = React.createRef();

        this.tree = this.createTree();

        this.state = {
            showBlanks: true,
            visibleNodes: props.visibleNodes ? props.visibleNodes : {},
            filterState: this.tree.filterState,
            expanded: [],
            model: null,
            filterText: '',
        };
    }

    componentDidMount() {
        setTimeout(this.refreshFilterTree, 100);
        this.collapseTree();
    }

    afterGuiAttached() {
        this.refreshFilterTree();
    }

    createTree(placeholderCompanies, knownCompanies) {
        return new CompanyNodeTree(placeholderCompanies, knownCompanies);
    }

    handleNodeToggled = (nodes) => {
        this.setState({ expanded: nodes });
    };

    collapseTree = () => {
        this.setState({ expanded: [] });
    };

    getCompanyParts() {
        const companyParts = [];
        this.props.api.forEachNode((node) => {
            if (node.data && this.props.doesRowPassOtherFilter(node)) {
                const value = this.valueGetter(
                    node.data[this.props.column.colDef.field]
                );

                if (value) {
                    const parts = Array.isArray(value) ? value : [value];
                    Object.values(parts)
                        .filter(
                            (lp) => lp.partType !== ENTITY_PART_TYPE_SEPARATOR
                        )
                        .forEach((lp) => companyParts.push(lp));
                }
            }
        });

        return [...new Set(companyParts)];
    }

    isSearchMatched = (company) => {
        const text = this.state.filterText.toLowerCase();
        return (
            company.name.toLowerCase().includes(text) ||
            company.parentName?.toLowerCase().includes(text)
        );
    };

    refreshCompanies(companies, visibleNodes) {
        companies.filter(this.isSearchMatched).forEach((company) => {
            const hierarchy = this.tree.getNodeAncestors(
                company.type + company.id
            );

            if (hierarchy) {
                let nodes = this.state.expanded;
                const text = this.state.filterText.toLowerCase();
                if (text !== '' && company.name.toLowerCase().includes(text)) {
                    for (const node of hierarchy) {
                        if (!nodes.includes(node)) {
                            nodes.push(node);
                        }
                    }
                }
                hierarchy.forEach((i) => (visibleNodes[i] = true));
            }
        });
    }

    refreshFilterTree = async () => {
        const companyParts = this.getCompanyParts();

        const placeholderCompanyIds = companyParts
            .filter((cp) => cp.partType === ENTITY_PART_TYPE_PLACEHOLDER)
            .map((ap) => ap.value);
        const accountIds = companyParts
            .filter((cp) => cp.partType === ENTITY_PART_TYPE_GAIN_ACCOUNT)
            .map((ap) => ap.value);
        const accountGroupIds = companyParts
            .filter((cp) => cp.partType === ENTITY_PART_TYPE_GAIN_ACCOUNT_GROUP)
            .map((ap) => ap.value);
        const customAccountsIds = companyParts
            .filter((cp) => cp.partType === ENTITY_PART_TYPE_CUSTOM_ACCOUNT)
            .map((ap) => ap.value);

        const accountsPromise =
            accountIds.length > 0
                ? ThirdPartyCompanyApi.getAccountsByIds(accountIds)
                : Promise.resolve([]);
        const accountGroupsPromise =
            accountGroupIds.length > 0
                ? ThirdPartyCompanyApi.getAccountGroupsByIds(accountGroupIds)
                : Promise.resolve([]);
        const customAccountsPromise =
            customAccountsIds.length > 0
                ? ThirdPartyCompanyApi.getCustomAccountsByIds(customAccountsIds)
                : Promise.resolve([]);

        const [accounts, accountGroups, customAccounts] = await Promise.all([
            accountsPromise,
            accountGroupsPromise,
            customAccountsPromise,
        ]);

        const knownCompanies = [
            ...accountGroups,
            ...accounts,
            ...customAccounts,
        ];

        const currentFilterState = this.state.filterState;
        this.tree = this.createTree(placeholderCompanyIds, knownCompanies);
        this.tree.setFilterState(currentFilterState);
        const visibleNodes = {};

        const placeholderCompanies = placeholderCompanyIds.map((id) => ({
            id: id,
            name: id,
            type: ENTITY_PART_TYPE_PLACEHOLDER,
            parentName: null,
        }));

        this.refreshCompanies(
            [...placeholderCompanies, ...knownCompanies],
            visibleNodes
        );

        if (this.state.filterText.toLowerCase() === '') {
            this.collapseTree();
        }

        this.setState(
            {
                filterState: currentFilterState,
                visibleNodes: visibleNodes,
            },
            () => this.props.filterChangedCallback()
        );
    };

    handleBlanksChange = (_, checked) => {
        this.setState({ showBlanks: checked }, () =>
            this.props.filterChangedCallback()
        );
    };

    handleSearchChange = (event) => {
        this.setState({ filterText: event.target.value.trimStart() }, () => {
            this.refreshFilterTree();
            this.props.filterChangedCallback();
        });
    };

    handleKeyDown = (event) => {
        if (event.keyCode === KEY_ENTER) {
            this.filterGridByVisibleNodes();
        }
    };

    filterGridByVisibleNodes() {
        this.tree.filterByVisibleNodes(this.tree, this.state.visibleNodes);

        this.setState(
            {
                showBlanks: false,
                filterState: this.tree.filterState,
            },
            () => {
                this.props.filterChangedCallback();
            }
        );
    }

    handleAllCompaniesChange = (_, checked) => {
        const state = checked
            ? CompanyNodeTree.States.CHECKED
            : CompanyNodeTree.States.UNCHECKED;
        this.tree.setCheckbox('_', state);

        this.setState({ filterState: this.tree.filterState }, () => {
            this.props.filterChangedCallback();
        });
    };

    handleNodeClicked = (treeNode, checked) => {
        let { showBlanks } = this.state;

        this.tree.setCheckbox(
            treeNode.key,
            checked
                ? CompanyNodeTree.States.CHECKED
                : CompanyNodeTree.States.UNCHECKED
        );

        this.setState({ showBlanks, filterState: this.tree.filterState }, () =>
            this.props.filterChangedCallback()
        );
    };

    clearFilter = (shouldClearText = true) => {
        this.tree.clearFilter();
        this.setState(
            {
                showBlanks: true,
                filterState: this.tree.filterState,
                filterText: shouldClearText ? '' : this.state.filterText,
            },
            () => {
                this.refreshFilterTree();
                this.props.filterChangedCallback();
            }
        );
    };

    valueGetter(rowValue) {
        return this.props.fieldName && rowValue
            ? rowValue[this.props.fieldName]
            : rowValue;
    }

    isShowingAllCompanies = () => {
        return this.tree.filterState.entityFilterSet.includes(
            CompanyNodeTree.RootNodeKey
        );
    };

    isFilterActive() {
        return !this.state.showBlanks || !this.isShowingAllCompanies();
    }

    doesFilterPass(params) {
        const value = this.valueGetter(this.props.valueGetter(params.node));
        if (!value) {
            return this.state.showBlanks;
        }

        const parts = Array.isArray(value) ? value : [value];
        const companyParts = parts.filter(
            (cp) => cp.partType !== ENTITY_PART_TYPE_SEPARATOR
        );

        if (
            (!companyParts || companyParts.length === 0) &&
            this.state.showBlanks
        ) {
            return true;
        }

        if (companyParts && companyParts.length > 0) {
            if (this.isShowingAllCompanies()) {
                return true;
            }

            for (const part of companyParts) {
                if (this.tree.isChecked(part.partType + part.value)) {
                    return true;
                }
            }
        }

        return false;
    }

    getModel() {
        const { filterState, showBlanks } = this.state;
        return this.isShowingAllCompanies() && showBlanks
            ? null
            : { filterState, showBlanks };
    }

    setModel(model) {
        if (model) {
            this.tree.setFilterState(model.filterState);
            this.setState({
                filterState: model.filterState,
                showBlanks: model.showBlanks,
            });
        } else {
            // do not clear search when checking both ALL and BLANKS(see getModel method)
            this.clearFilter(
                this.state.filterState?.entityFilterSet.length === 0
            );
        }
    }

    render() {
        const elements = [
            <input
                ref={this.inputRef}
                style={{ marginBottom: '10px' }}
                className="ag-input-field-input ag-text-field-input"
                type="text"
                value={this.state.filterText}
                onChange={this.handleSearchChange}
                onKeyDown={this.handleKeyDown}
                placeholder="Search..."
                autoFocus
            />,
            [
                {
                    type: 'checkbox',
                    checked: this.state.showBlanks,
                    onChange: this.handleBlanksChange,
                    label: blankKeyText,
                },
                {
                    type: 'checkbox',
                    checked: this.isShowingAllCompanies(),
                    onChange: this.handleAllCompaniesChange,
                    label: 'ALL',
                },
            ],
            <FilterTreeView
                tree={this.tree}
                visibleNodes={this.state.visibleNodes}
                expanded={this.state.expanded}
                onNodeClicked={this.handleNodeClicked}
                onNodeToggle={this.handleNodeToggled}
            />,
        ];

        return <FilterGenerator elements={elements} clear={this.clearFilter} />;
    }
}

CompaniesFilter.propTypes = {
    filterChangedCallback: PropTypes.func.isRequired,
};

export default CompaniesFilter;
