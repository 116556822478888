import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DurationNodeTree from '../tools/DurationNodeTree';
import FilterTreeView from '_legacy/components/grid/filters/FilterTreeView';
import { blankKeyText } from '_legacy/constants/gridText';
import { FilterGenerator } from '_legacy/components/grid/filters/FilterGenerator';
import { KEY_ENTER } from '_legacy/constants/keyboardCodes';
import DurationPartsKeyCreator from '../keyCreators/DurationPartsKeyCreator';
import { calculateDurationAverageDays } from '../tools/DurationPartsConverter';
import { LEGS_VALUE, PNR } from '../../../shared/columns/models/DurationUnits';

class DurationFilter extends Component {
    constructor(props) {
        super(props);

        this.inputRef = React.createRef();
        this.tree = this.createTree();

        this.state = {
            showBlanks: true,
            visibleNodes: props.visibleNodes ? props.visibleNodes : {},
            filterState: this.tree.filterState,
            expanded: [],
            model: null,
            filterText: '',
        };
    }

    componentDidMount() {
        setTimeout(this.refreshFilterTree, 100);
        this.collapseTree();
    }

    afterGuiAttached() {
        this.refreshFilterTree();
    }

    createTree(durationPartsArray) {
        return new DurationNodeTree(durationPartsArray);
    }

    handleNodeToggled = (nodes) => {
        this.setState({ expanded: nodes });
    };

    collapseTree = () => {
        this.setState({ expanded: [] });
    };

    getDurationPartsArray() {
        const durationPartsArray = [];
        this.props.api.forEachNode((node) => {
            if (
                node.data &&
                node.data.durationParts &&
                node.data.durationParts.length !== 0 &&
                this.props.doesRowPassOtherFilter(node)
            ) {
                const parts = node.data.durationParts;
                const key = DurationPartsKeyCreator({ value: parts });
                const averageValue = calculateDurationAverageDays(parts);
                const parentNodeKey = this.getParentNodeKey(averageValue, key);

                if (!durationPartsArray.some((x) => x.key === key)) {
                    durationPartsArray.push({
                        key,
                        averageValue,
                        parentNodeKey,
                    });
                }
            }
        });

        return durationPartsArray;
    }

    getParentNodeKey(averageValue, key) {
        const shortPeriodRange = 300;
        const oneYearPeriodRange = 630;
        const twoYearsPeriodRange = 960;

        const shortDirectionsParentKey = 'SHORT';
        const oneYearDirectionsParentKey = 'ONE YEAR';
        const twoYearDirectionsParentKey = 'TWO YEARS';
        const threeYearDirectionsParentKey = 'THREE YEARS';
        const ladenLegsDirectionsParentKey = 'LADEN LEGS';

        if (key === PNR) return DurationNodeTree.RootNodeKey;
        if (key.includes(LEGS_VALUE)) return ladenLegsDirectionsParentKey;
        if (averageValue < shortPeriodRange) return shortDirectionsParentKey;
        if (averageValue < oneYearPeriodRange)
            return oneYearDirectionsParentKey;
        if (averageValue < twoYearsPeriodRange)
            return twoYearDirectionsParentKey;

        return threeYearDirectionsParentKey;
    }

    isSearchMatched = (duration) => {
        const text = this.state.filterText.toLowerCase();
        return (
            duration.key.toLowerCase().includes(text) ||
            duration.parentNodeKey.toLowerCase().includes(text)
        );
    };

    refreshDurations(durationPartsArray, visibleNodes) {
        durationPartsArray.filter(this.isSearchMatched).forEach((duration) => {
            const hierarchy = this.tree.getNodeAncestors(duration.key);
            if (hierarchy) {
                let nodes = this.state.expanded;
                const text = this.state.filterText.toLowerCase();
                if (text !== '') {
                    for (const node of hierarchy) {
                        if (!nodes.includes(node)) {
                            nodes.push(node);
                        }
                    }
                }

                hierarchy.forEach((i) => (visibleNodes[i] = true));
            }
        });
    }

    refreshFilterTree = () => {
        const durationPartsArray = this.getDurationPartsArray();

        const currentFilterState = this.state.filterState;
        this.tree = this.createTree(durationPartsArray);
        this.tree.setFilterState(currentFilterState);
        const visibleNodes = {};

        this.refreshDurations(durationPartsArray, visibleNodes);

        if (this.state.filterText.toLowerCase() === '') {
            this.collapseTree();
        }

        this.setState(
            {
                filterState: currentFilterState,
                visibleNodes: visibleNodes,
            },
            () => this.props.filterChangedCallback()
        );
    };

    handleBlanksChange = (_, checked) => {
        this.setState({ showBlanks: checked }, () =>
            this.props.filterChangedCallback()
        );
    };

    handleSearchChange = (event) => {
        this.setState({ filterText: event.target.value.trimStart() }, () => {
            this.refreshFilterTree();
            this.props.filterChangedCallback();
        });
    };

    handleKeyDown = (event) => {
        if (event.keyCode === KEY_ENTER) {
            this.filterGridByVisibleNodes();
        }
    };

    filterGridByVisibleNodes() {
        this.tree.filterByVisibleNodes(this.tree, this.state.visibleNodes);

        this.setState(
            {
                showBlanks: false,
                filterState: this.tree.filterState,
            },
            () => {
                this.props.filterChangedCallback();
            }
        );
    }

    handleAllDurationsChange = (_, checked) => {
        const state = checked
            ? DurationNodeTree.States.CHECKED
            : DurationNodeTree.States.UNCHECKED;
        this.tree.setCheckbox('_', state);

        this.setState({ filterState: this.tree.filterState }, () => {
            this.props.filterChangedCallback();
        });
    };

    handleNodeClicked = (treeNode, checked) => {
        let { showBlanks } = this.state;

        this.tree.setCheckbox(
            treeNode.key,
            checked
                ? DurationNodeTree.States.CHECKED
                : DurationNodeTree.States.UNCHECKED
        );

        this.setState({ showBlanks, filterState: this.tree.filterState }, () =>
            this.props.filterChangedCallback()
        );
    };

    clearFilter = (shouldClearText = true) => {
        this.tree.clearFilter();
        this.setState(
            {
                showBlanks: true,
                filterState: this.tree.filterState,
                filterText: shouldClearText ? '' : this.state.filterText,
            },
            () => {
                this.refreshFilterTree();
                this.props.filterChangedCallback();
            }
        );
    };

    valueGetter(rowValue) {
        return this.props.fieldName && rowValue
            ? rowValue[this.props.fieldName]
            : rowValue;
    }

    isShowingAllDurations = () => {
        return this.tree.filterState.entityFilterSet.includes(
            DurationNodeTree.RootNodeKey
        );
    };

    isFilterActive() {
        return !this.state.showBlanks || !this.isShowingAllDurations();
    }

    doesFilterPass(params) {
        const parts = this.valueGetter(this.props.valueGetter(params.node));

        if ((!parts || parts.length === 0) && this.state.showBlanks) {
            return true;
        }

        if (parts && parts.length > 0) {
            if (this.isShowingAllDurations()) {
                return true;
            }

            const keyNode = DurationPartsKeyCreator({ value: parts });

            if (this.tree.isChecked(keyNode)) {
                return true;
            }
        }
    }

    getModel() {
        const { filterState, showBlanks } = this.state;
        return this.isShowingAllDurations() && showBlanks
            ? null
            : { filterState, showBlanks };
    }

    setModel(model) {
        if (model) {
            this.tree.setFilterState(model.filterState);
            this.setState({
                filterState: model.filterState,
                showBlanks: model.showBlanks,
            });
        } else {
            // do not clear search when checking both ALL and BLANKS(see getModel method)
            this.clearFilter(
                this.state.filterState?.entityFilterSet.length === 0
            );
        }
    }

    render() {
        const elements = [
            <input
                ref={this.inputRef}
                style={{ marginBottom: '10px' }}
                className="ag-input-field-input ag-text-field-input"
                type="text"
                value={this.state.filterText}
                onChange={this.handleSearchChange}
                onKeyDown={this.handleKeyDown}
                placeholder="Search..."
                autoFocus
            />,
            [
                {
                    type: 'checkbox',
                    checked: this.state.showBlanks,
                    onChange: this.handleBlanksChange,
                    label: blankKeyText,
                },
                {
                    type: 'checkbox',
                    checked: this.isShowingAllDurations(),
                    onChange: this.handleAllDurationsChange,
                    label: 'ALL DURATIONS',
                },
            ],
            <FilterTreeView
                tree={this.tree}
                visibleNodes={this.state.visibleNodes}
                expanded={this.state.expanded}
                onNodeClicked={this.handleNodeClicked}
                onNodeToggle={this.handleNodeToggled}
            />,
        ];

        return <FilterGenerator elements={elements} clear={this.clearFilter} />;
    }
}

DurationFilter.propTypes = {
    filterChangedCallback: PropTypes.func.isRequired,
};

export default DurationFilter;
