import { DATASET_DRY_CARGO } from './Datasets';

export const areasByDataset = (datasetId) => {
    switch (datasetId) {
        case DATASET_DRY_CARGO:
            return [
                'CONT/BALTIC',
                'EC AUS',
                'ECSA',
                'MED/B.SEA',
                'NCHINA/CIS',
                'NCSA',
                'NOPAC',
                'OTHER',
                'SAFR/INDIA',
                'SEASIA',
                'USEC',
                'USG',
                'WAFR',
                'WAUS',
            ];
        default:
            return ['WAFR', 'MED', 'NORTH', 'STATES', 'EAST'];
    }
};

export const groupSortingByAreas = (datasetId) => {
    switch (datasetId) {
        case DATASET_DRY_CARGO:
            return [
                'CONT/BALTIC',
                'USEC',
                'MED/B.SEA',
                'WAFR',
                'USG',
                'NCSA',
                'ECSA',
                'NCHINA/CIS',
                'NOPAC',
                'EC AUS',
                'SEASIA',
                'WAUS',
                'SAFR/INDIA',
                'OTHER',
            ];
        default:
            return ['WAFR', 'MED', 'NORTH', 'STATES', 'EAST'];
    }
};

export const getAreasGeared = () => {
    return [
        'AUSSIE',
        'CONT/BALTIC',
        'ECSA',
        'MED/B.SEA',
        'N ASIA',
        'NAMER',
        'NOPAC',
        'LAKES',
        'OTHER',
        'SAFR/INDIA',
        'SEASIA',
        'WAFR',
        'WCAMER',
    ];
};

export const ensureAreasGroupingByDataset = (datasetId, rowGroupsSorting) => {
    const areas = areasByDataset(datasetId);
    const currentSorting = rowGroupsSorting.find(
        (rgs) => rgs.colId === 'areas'
    );
    if (currentSorting?.sorting) {
        return currentSorting.sorting.every((s) => areas.includes(s));
    }
    return false;
};
